import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import ChainInfo from "../../views/chain-info"
import { Seo } from "../../components/seo"
import {
  exportToCsv,
  getBanner,
  operatorAddressToAccount,
  reduceValue,
} from "../../components/utils"
import axios from "axios"
import * as mainStyles from "../../styles/index.module.scss"
import Spinner from "../../components/spinner"
import {
  BsCheckCircleFill,
  BsDownload,
  BsExclamationCircleFill,
  BsFillExclamationTriangleFill,
} from "react-icons/bs"

import { parse, addDays, format, parseISO, startOfDay } from "date-fns/esm"
export const Head = ({
  location,
  data: { chainsJson: chain, allFile: files },
}) => (
  <Seo
    title={`${chain.name} uptime statistics`}
    description={chain.about}
    pathname={location.pathname}
    image={getBanner(chain.key, files.edges)}
  />
)

const s3Root = "https://static.brocha.in/uptick"

let curDate = parseISO("2023-03-27")
const availableDates = []
while (curDate < startOfDay(new Date())) {
  availableDates.push(format(curDate, "yyyy-MM-dd"))
  curDate = addDays(curDate, 1)
}

const ValidatorStats = props => {
  const { chainsJson, allFile } = props.data

  const [selectedFromDate, setSelectedFromDate] = React.useState(
    availableDates[0]
  )
  const [selectedToDate, setSelectedToDate] = React.useState(availableDates[0])
  const [blocks, setBlocks] = React.useState([0, 0])
  const [uptimeData, setUptimeData] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const fetchData = async () => {
    const uptimeData = {}
    setLoading(true)
    setBlocks([0, 0])
    let currentDate = parseISO(selectedFromDate, "yyyy-MM-dd")
    const toDate = parseISO(selectedToDate, "yyyy-MM-dd")
    let localBlocks = blocks
    while (currentDate <= toDate) {
      const selectedDate = format(currentDate, "yyyy-MM-dd")
      try {
        const newBlocks = await axios
          .get(`${s3Root}/blocks-${selectedDate}.json`)
          .then(response => response.data)
        const startBlock =
          selectedDate === selectedFromDate ? newBlocks[0] : localBlocks[0]
        console.log(
          selectedDate,
          selectedFromDate,
          selectedDate === selectedFromDate,
          newBlocks[0],
          blocks[0],
          startBlock,
          [startBlock, newBlocks[1]]
        )
        localBlocks = [startBlock, newBlocks[1]]
        setBlocks(localBlocks)
        const numBlocks = newBlocks[1] - startBlock + 1

        const uptime = await axios
          .get(`${s3Root}/uptime-${selectedDate}.json`)
          .then(response => response.data)
        Object.values(uptime).forEach(val => {
          if (!uptimeData[val.AccAddress]) {
            uptimeData[val.AccAddress] = {
              valoper: val.AccAddress,
              date: `${selectedFromDate} -  ${selectedToDate}`,
              signedBlocks: 0,
            }
          }
          const signedBlocks =
            uptimeData[val.AccAddress].signedBlocks + parseInt(val.times)
          uptimeData[val.AccAddress].signedBlocks = signedBlocks

          uptimeData[val.AccAddress].uptime = (signedBlocks / numBlocks) * 100
          uptimeData[val.AccAddress].jailed =
            uptimeData[val.AccAddress].jailed || val.Jailed
        })
      } catch (e) {
        console.log(e)
      }
      currentDate = addDays(currentDate, 1)
    }
    const uptimes = Object.values(uptimeData).sort(
      (a, b) => b.uptime - a.uptime
    )
    setUptimeData(uptimes)
    setLoading(false)
  }

  React.useEffect(() => {
    fetchData()
  }, [selectedFromDate, selectedToDate])

  React.useEffect(() => {
    fetchData()
  }, [])

  const numberFormatter = Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
  })

  const [validators, setValidators] = React.useState([])
  React.useEffect(() => {
    ;(async () => {
      const data = await axios
        .get(
          "https://uptick-rest.brocha.in/cosmos/staking/v1beta1/validators?pagination.limit=200&status=BOND_STATUS_BONDED"
        )
        .then(r => r.data.validators)
      const valMap = data.reduce(
        (vals, val) => ({
          ...vals,
          [operatorAddressToAccount(val.operator_address)]:
            val.description.moniker,
        }),
        {}
      )
      setValidators(valMap)
    })()
  }, [])

  const remapValidator = account =>
    validators[account] ? (
      <React.Fragment>
        {validators[account]}
        <br />
        <small>{account}</small>
      </React.Fragment>
    ) : (
      account
    )

  return (
    <Layout>
      <ChainInfo chain={chainsJson} data={allFile.edges} />
      <h2>Uptime statstics</h2>
      <select
        value={selectedFromDate}
        onChange={e => setSelectedFromDate(e.target.value)}
        style={{ marginRight: "1rem" }}
      >
        {availableDates.map(d => (
          <option disabled={d > selectedToDate}>{d}</option>
        ))}
      </select>
      <select
        value={selectedToDate}
        onChange={e => setSelectedToDate(e.target.value)}
        style={{ marginRight: "1rem" }}
      >
        {availableDates.map(d => (
          <option disabled={d < selectedFromDate}>{d}</option>
        ))}
      </select>
      <button
        type="button"
        onClick={() =>
          exportToCsv(
            `uptick-uptime-${selectedFromDate}-${selectedToDate}.csv`,
            [
              [
                "Account address",
                "Date range",
                "Signed blocks",
                "Uptime %",
                "Jailed",
              ],
              ...uptimeData.map(val => Object.values(val)),
            ]
          )
        }
      >
        <BsDownload /> Download CSV
      </button>
      <div style={{ marginTop: "0.5rem" }}>
        Block {blocks[0]} - {blocks[1]}
      </div>
      <table>
        <thead>
          <tr>
            <th>Validator</th>
            <th>Uptime</th>
            <th>Jailed</th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            uptimeData.map(val => {
              if (!val) return null
              return (
                <tr>
                  <td>{remapValidator(val.valoper)}</td>
                  <td>{numberFormatter.format(val.uptime)} %</td>
                  <td>{val.jailed ? "Jailed" : ""}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={3}>
                <Spinner /> Loading...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Layout>
  )
}

export default ValidatorStats

export const query = graphql`
  query {
    chainsJson(key: { eq: "uptick" }) {
      id
      about
      explorerUrl
      hidden
      key
      logo
      name
      site
      services {
        tmVersion
        gitRepo
        binary
        root
        publicRpc
        publicGrpc
        publicRest
        seedNode
        chainId
        denom
        snapshot
        installation {
          genesisUrl
          addrbookUrl
          seeds
          installScript
          versions {
            gitTag
            name
          }
        }
        stateSync {
          rpc
          peer
        }
        networkMap
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
